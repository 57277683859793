const cards = [
  {
    title: "DEFINITION",
    desc: <p>Define Mission & Target <br /> Corporate Objectives</p>
  },
  {
    title: "ANALYSIS",
    desc: <p>Identify Opportunities Analyze <br /> Situations (5C, SWOT, PEST)</p>
  },
  {
    title: "STRATEGY",
    desc: <p>Identify Target Audience <br /> Set Measurable Goals & Develop Budget</p>
  },
  {
    title: "PLAN & EXECUTION",
    desc: <p>Product Development, <br /> Pricing, Promotion & Distribution</p>
  },
  {
    title: "EVALUATION",
    desc: <p>Evaluate - Revert - Refine - Repeat</p>
  },
];

export default function OurApproach() {
  return (
    <section className="ourApproach" data-aos="fade-center">
      <div className="container">
        <h3 className="section-title black">Our Approach</h3>
        <div>
          <img src="/images/v1/DLC-round.svg" alt="" />
        </div>
      </div>
    </section>
  )
}