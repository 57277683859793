import { useState } from "react";
import { useHistory } from "react-router-dom";
import AdminHeader from "../components/AdminHeader";
import { useAuth } from "../provider/AuthProvider";

export default function AdminAuth() {
  const { login } = useAuth();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState({ email: "", password: "" });

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    login(formValues)
      .then(() => history.push({ state: { from: "login" }, pathname: "/admin/dashboard" }))
      .catch(err => {
        setError(err);
      })
  }

  return (
    <div className="container">
      <AdminHeader />
      
      <div className="adminAuth">
        <div className="adminAuth__wrapper">
          <h3>Sign into Latitude</h3>

          <form onSubmit={handleSubmit} className="adminAuth__form">
            {error && <div>
              <div className="error">{error}</div>
            </div>}
            <div>
              <label>
                Email
                <input type="email" value={setFormValues["email"]} onChange={({ target }) => setFormValues(state => ({ ...state, email: target.value }))} />
              </label>
            </div>

            <div>
              <label>
                Password
                <input type="password" value={setFormValues["password"]} onChange={({ target }) => setFormValues(state => ({ ...state, password: target.value }))} />
              </label>
            </div>

            <div>
              <button type="submit" className="btn btn-primary">Login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}