import { useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-scroll";

export default function Navbar() {
  const [hamburgerClicked, setHamburgerClicked] = useState(false);

 return (
   <header className="header">
     <div className="container">
      <span className="header__logo">
        <Link to="main" spy={true} smooth={true} offset={50} duration={500}>
          <img src="/images/latitude-logo.svg" alt="logo" />
        </Link>
      </span>

      <nav>
        <span className="header__ham" onClick={() => setHamburgerClicked(state => !state)}>
          <FaBars />
        </span>
        <ul className={`header__nav ${hamburgerClicked ? "active" : ""}`}>
          <li><Link to="whoWeAre" spy={true} smooth={true} offset={50} duration={500}>Who We Are</Link></li>
          <li><Link to="whatWeDo" spy={true} smooth={true} offset={50} duration={500}>What We Do</Link></li>
          <li><Link to="howWeDoIt" spy={true} smooth={true} offset={50} duration={500}>How We Do It</Link></li>
          <li><Link to="whoWeWorkWith" spy={true} smooth={true} offset={50} duration={500}><span>Who We Work With</span></Link></li>
          <li><Link to="contact" spy={true} smooth={true} offset={50} duration={500}><span>Contact US</span></Link></li>
        </ul>
      </nav>
     </div>
   </header>
 ) 
}