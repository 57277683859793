import { Link } from "react-scroll";

export default function Hero() {
  return (
    <section className="hero" style={{
      background: 'url("/images/v1/hero.png")'
    }}>
      <div className="container">
        <h4 className="hero__title" data-aos="fade-right">LATITUDE23</h4>
        <h3 className="hero__subTitle" data-aos="fade-right">DIGITAL AND BRANDING SOLUTIONS</h3>
        <Link to="contact" spy={true} smooth={true} offset={50} duration={500}>
          <button className="btn btn-primary text-bold" data-aos="fade-right">GET IN TOUCH</button>
        </Link>
      </div>
    </section>
  )
}