import { useState } from "react";
import Swl from "sweetalert2";

const INITIAL_VALUES = {
  name: "",
  email: "",
  phone: "",
  message: "",
  ways_of_collaborate: [],
  hearingWays: [],
  send_promo_emails: false
}

export default function ContactUs() {
  const [loading, setLoading] = useState(false);
  const [contactValues, setContactValues] = useState({...INITIAL_VALUES});

  const setValuesToArray = (field, checked, value) => {
    const newArray = [...contactValues[field]];
    if (checked && !contactValues[field].includes(value)) {
      newArray.push(value);
    } else {
      const index = contactValues[field].findIndex(i => i === value);
      newArray.splice(index, 1);
    }
    setContactValues({...contactValues, [field]: newArray})
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch("/api/contacts", {
        method: "POST",
        body: JSON.stringify(contactValues),
        headers: { "Content-Type": "application/json" }
      });
      const data = await res.json();
      setLoading(false);
      setContactValues(INITIAL_VALUES);
      Swl.fire({
        icon: "success",
        title: data,
        showCloseButton: false,
        timer: 1000
      })
    } catch (err) {
      console.log(err);
    }
  }
  

  return (
    <section className="contact" id="contact" style={{
      background: 'linear-gradient(0deg, rgba(15, 36, 70, 0.3), rgba(15, 36, 70, 0.55)), linear-gradient(180deg, rgba(221, 106, 0, 0.2) 0%, rgba(221, 106, 0, 0) 100%), url("/images/contact_bg.png")'
    }}>
      <div className="container"  data-aos="fade-right">
        <h3>Send us a Message</h3>
        <h6>We will reach out to You!</h6>

        <form onSubmit={handleSubmit} className="contact__form">
          <div className="row">
            <label>
              <span>Your Name</span>
              <input type="text" value={contactValues["name"]} onChange={({ target }) => setContactValues({ ...contactValues, name: target.value })} />
            </label>
          </div>
          <div className="row">
            <label style={{ marginRight: "1rem" }}>
              <span>Email Address</span>
              <input type="text" value={contactValues["email"]} onChange={({ target }) => setContactValues({ ...contactValues, email: target.value })} />
            </label>
            <label style={{ marginLeft: "1rem" }}>
              <span>Phone Number</span>
              <input type="text" value={contactValues["phone"]} onChange={({ target }) => setContactValues({ ...contactValues, phone: target.value })} />
            </label>
          </div>
          <div className="row">
            <label>
              <span>Message</span>
              <textarea cols="30" rows="10" value={contactValues["message"]} onChange={({ target }) => setContactValues({ ...contactValues, message: target.value })} />
            </label>
          </div>
          <div className="row">
            <label>
              <span>How Can We Collaborate?</span>
              <div className="checkboxes">
                <span>
                  <label>
                    <input checked={!!contactValues["ways_of_collaborate"].includes("Website Development")} onChange={({ target }) => setValuesToArray("ways_of_collaborate", target.checked, "Website Development")} type="checkbox" /> Website Development
                  </label>
                  <label>
                    <input checked={!!contactValues["ways_of_collaborate"].includes("Mobile Application Development")} onChange={({ target }) => setValuesToArray("ways_of_collaborate", target.checked, "Mobile Application Development")} type="checkbox" /> Mobile Application Development
                  </label>
                  <label>
                    <input checked={!!contactValues["ways_of_collaborate"].includes("Software Development")} onChange={({ target }) => setValuesToArray("ways_of_collaborate", target.checked, "Software Development")} type="checkbox" /> Software Development
                  </label>
                  <label>
                    <input checked={!!contactValues["ways_of_collaborate"].includes("Custom Apparel")} onChange={({ target }) => setValuesToArray("ways_of_collaborate", target.checked, "Custom Apparel")} type="checkbox" /> Custom Apparel
                  </label>
                  <label>
                    <input checked={!!contactValues["ways_of_collaborate"].includes("Search Engine Optimization (SEO)")} onChange={({ target }) => setValuesToArray("ways_of_collaborate", target.checked, "Search Engine Optimization (SEO)")} type="checkbox" /> Search Engine Optimization (SEO)
                  </label>
                  <label>
                    <input checked={!!contactValues["ways_of_collaborate"].includes("Search Engine Marketing (SEM)")} onChange={({ target }) => setValuesToArray("ways_of_collaborate", target.checked, "Search Engine Marketing (SEM)")} type="checkbox" /> Search Engine Marketing (SEM)
                  </label>
                  <label>
                    <input checked={!!contactValues["ways_of_collaborate"].includes("Social Media Marketing (SMM)")} onChange={({ target }) => setValuesToArray("ways_of_collaborate", target.checked, "Social Media Marketing (SMM)")} type="checkbox" /> Social Media Marketing (SMM)
                  </label>
                  <label>
                    <input checked={!!contactValues["ways_of_collaborate"].includes("Email Marketing")} onChange={({ target }) => setValuesToArray("ways_of_collaborate", target.checked, "Email Marketing")} type="checkbox" /> Email Marketing 
                  </label>
                  <label>
                    <input checked={!!contactValues["ways_of_collaborate"].includes("Other")} onChange={({ target }) => setValuesToArray("ways_of_collaborate", target.checked, "Other")} type="checkbox" /> Other 
                  </label>
                </span>
              </div>
            </label>
          </div>
          <div className="row">
            <label>
              <span>Where did you hear about us?</span>
              <div className="checkboxes">
                <span>
                  <label>
                    <input type="checkbox" checked={!!contactValues["hearingWays"].includes("Social Media")} onChange={({ target }) => setValuesToArray("hearingWays", target.checked, "Social Media")} /> Social Media
                  </label>
                  <label>
                    <input type="checkbox" checked={!!contactValues["hearingWays"].includes("A Friend")} onChange={({ target }) => setValuesToArray("hearingWays", target.checked, "A Friend")} /> A Friend
                  </label>
                  <label>
                    <input type="checkbox" checked={!!contactValues["hearingWays"].includes("An Online Ad")} onChange={({ target }) => setValuesToArray("hearingWays", target.checked, "An Online Ad")} /> An Online Ad
                  </label>
                  <label>
                    <input type="checkbox" checked={!!contactValues["hearingWays"].includes("A Business partner")} onChange={({ target }) => setValuesToArray("hearingWays", target.checked, "A Business partner")} /> A Business partner
                  </label>
                </span>
              </div>
            </label>
          </div>
          <div className="confirm">
            <label>
              <input type="checkbox" checked={contactValues.send_promo_emails} onChange={({ target }) => setContactValues({ ...contactValues, send_promo_emails: target.checked })} /> Send Me Promo Emails
            </label>
          </div>
          <button disabled={loading} type="submit" className="btn btn-primary">SEND MESSAGE</button>
        </form>
      </div>
    </section>
  )
}