import { Link } from "react-scroll";

export default function HowWeDoIt() {
  return (
    <section className="howWeDoIt" id="howWeDoIt" style={{
      background: 'url("/images/v1/section_bg.png")'
    }}>
      <div className="container">
        <h3 className="section-title">How We Do It</h3>

        <div className="howWeDoIt__cards">
          <div className="howWeDoIt__card"  data-aos="fade-center">
            <img src="/images/v1/icons/web.svg" alt="" />
            <h4>Website Development</h4>

            <div>
              <h4>Website Development</h4>
              <p>Fully customized web application development according to your needs using open web technologies like html, css,  javascript etc. or template based builders such as wordpress, shopify etc.</p>
            </div>
          </div>

          <div className="howWeDoIt__card"  data-aos="fade-center">
            <img src="/images/v1/icons/mobile.svg" alt="" />
            <h4>Mobile Application Development</h4>

            <div>
              <h4>Mobile Application Development</h4>
              <p>Native mobile app development for major mobile platforms such as Android and iOS devices. </p>
            </div>
          </div>

          <div className="howWeDoIt__card"  data-aos="fade-center">
            <img src="/images/v1/icons/software.svg" alt="" />
            <h4>Software Development</h4>

            <div>
              <h4>Software Development</h4>
              <p>Custom software solutions that meet all your needs and scale your business operations sustainably.</p>
            </div>
          </div>

          <div className="howWeDoIt__card"  data-aos="fade-center">
            <img src="/images/v1/icons/custom_apparel.svg" alt="" />
            <h4>Custom Apparel</h4>

            <div>
              <h4>Custom Apparel</h4>
              <p>We design and manufacture custom apparel according to the highest quality standards. We provide t-shirts, hoodies, hats,  jerseys and any other merchandise based on your requirements. </p>
            </div>
          </div>

          <div className="howWeDoIt__card"  data-aos="fade-center">
            <img src="/images/v1/icons/seo.svg" alt="" />
            <h4>Search Engine Optimization (SEO)</h4>

            <div>
              <h4>Search Engine Optimization (SEO)</h4>
              <p>More than half of all web traffic comes from organic web searches, let us optimize your website to increase your businesses’ visibility and presence online.</p>
            </div>
          </div>
          
          <div className="howWeDoIt__card"  data-aos="fade-center">
            <img src="/images/v1/icons/sem.svg" alt="" />
            <h4>Search Engine Marketing (SEM)</h4>

            <div>
              <h4>Search Engine Marketing (SEM)</h4>
              <p>Search Engine Marketing is useful for promoting a business directly on the search engine result page. We make sure your product gets a higher range of visibility on Google.</p>
            </div>
          </div>

          <div className="howWeDoIt__card"  data-aos="fade-center">
            <img src="/images/v1/icons/smm.svg" alt="" />
            <h4>Social Media Marketing (SMM)</h4>

            <div>
              <h4>Social Media Marketing (SMM)</h4>
              <p>We design, develop and execute social media marketing campaigns on major Social Media platforms such as Facebook, Instagram etc to increase brand awareness as well as conversions.</p>
            </div>
          </div>

          <div className="howWeDoIt__card"  data-aos="fade-center">
            <img src="/images/v1/icons/em.svg" alt="" />
            <h4>Email Marketing</h4>

            <div>
              <h4>Email Marketing</h4>
              <p>Email marketing can boost a business to the next level. It’s easier to grab attention from a large chunk of audiences through email marketing. Our experts will find suitable leads and reach them via unconventional catchy emails that are hard to ignore.</p>
            </div>
          </div>
        </div>

        <div style={{ textAlign: 'center', marginTop: "66px" }}>
          <Link to="contact" spy={true} smooth={true} offset={50} duration={500}>
            <button className="btn btn-primary text-bold" data-aos="fade-right">GET IN TOUCH</button>
          </Link>
        </div>
      </div>
    </section>
  );
}