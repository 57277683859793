import moment from "moment";
import { useEffect, useState } from "react";

export default function ContactResponses() {
  const [message, setMessage] = useState("");
  const [contactResponses, setContactResponses] = useState([]);

  useEffect(() => {
    getContactResponses();
  }, []);

  const getContactResponses = () => {
    fetch("/api/contacts", { headers: { Authorization: "Bearer " + localStorage.getItem("tid") } })
      .then((res) => res.json())
      .then((data) => {
        setContactResponses(data);
      })
    }

  return (
    <div className="contactResponses">
      <h3>Contact Form Responses</h3>

      <div className="contactResponses__list">
        {contactResponses.map(item => (
          <div key={item._id} className="contactResponses__item">
            <div className="contactResponses__itemHeader">
              <div>
                <span><strong>Name:</strong> {item.name}</span>
                <span><strong>Email:</strong> {item.email} <a href={`mailto:${item.email}`}>Reply</a></span>
                <span><strong>Phone:</strong> {item.phone}</span>
              </div>
              <div>
                <p>{moment(item.createdAt).format('Do MMMM, YYYY - h:mm a')}</p>
              </div>
            </div>
            <p><strong>Message: <br /></strong>
              {item.message}
            </p>
            <h3>Collaboration Interests:</h3>
            <div className="contactResponses__itemChips">
              {item.ways_of_collaborate.map((item, ind) => (
                <span key={ind}>{item}</span>
              ))}
            </div>
            <h4><strong>Found Through:</strong> {item.hearingWays.join(", ")}</h4>
          </div>
        ))}
      </div>
    </div>
  )
}