
import "aos/dist/aos.css";
import { Fragment } from "react";
import Banner from "../components/Banner";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import HowWeDoIt from "../components/HowWeDoIt";
import Navbar from "../components/Navbar";
import OurApproach from '../components/OurApproach';
import ToolsAndTech from '../components/ToolsAndTech';
import WhatWeDo from "../components/WhatWeDo";
import WorkWithUs from "../components/WorkWithUs";

function HomePage() {
  return (
    <Fragment>
      <div id="main"></div>
      <Navbar />
      <div className="main">
        <Hero />
        <Banner id="whoWeAre">
          We respect and appreciate each business’s unique personality and work closely with our clients to develop high-quality products and campaigns which truly speak to their target audience. Our team of experts create high-impact strategies which are in tune with your branding to unlock your business’s true potential.
        </Banner>
        <WhatWeDo />
        <Banner>
          Latitude23 is a comprehensive solution provider for all of your branding and marketing needs. We take a holistic approach to build your brand’s identity and fulfill your vision through tailor-made strategies be it online or offline. 
        </Banner>
        <HowWeDoIt />
        <OurApproach />
        <WorkWithUs />
        <ToolsAndTech /> 
        <ContactUs />
        <Footer />
      </div>
    </Fragment>
  );
}

export default HomePage;
