import { BiPowerOff } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import AdminHeader from "../components/AdminHeader";
import AdminTab from "../components/AdminTab";
import ContactList from "../components/ContactList";
import ContactResponses from "../components/ContactResponses";
import { useAuth } from "../provider/AuthProvider";

export default function AdminDashboard() {
	const { logout } = useAuth();
	const history = useHistory();

	return (
		<div className="adminDashboard">
			<div className="container">
				<AdminHeader />

				<div className="adminDashboard__logout">
					<button
						type="button"
						onClick={() =>
							logout().then(() =>
								history.push({
									pathname: "/admin",
									state: { from: "/admin/dashboard" },
								})
							)
						}
					>
						<BiPowerOff /> Logout
					</button>
				</div>
				<h3>Dashboard</h3>

				<AdminTab
					tabs={[
						{
							id: 1,
							label: "All Contact Responses",
							component: ContactResponses,
						},
						{ id: 2, label: "Contact List", component: ContactList },
					]}
				/>
			</div>
		</div>
	);
}
