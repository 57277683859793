import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useEffect, useState } from "react";

const tools = [
  {
    src: "/images/v1/tools/tools/01.png",
  },
  {
    src: "/images/v1/tools/tools/02.png",
  },
  {
    src: "/images/v1/tools/tools/03.png",
  },
  {
    src: "/images/v1/tools/tools/04.png",
  },
  {
    src: "/images/v1/tools/tools/05.png",
  },
  {
    src: "/images/v1/tools/tools/06.png",
  },
  {
    src: "/images/v1/tools/tools/07.png",
  },
  {
    src: "/images/v1/tools/tools/08.png",
  },
  {
    src: "/images/v1/tools/tools/09.png",
  },
  {
    src: "/images/v1/tools/tools/10.png",
  },
  {
    src: "/images/v1/tools/tools/11.png",
  },
  {
    src: "/images/v1/tools/tools/12.png",
  },
  {
    src: "/images/v1/tools/tools/13.png",
  },
  {
    src: "/images/v1/tools/tools/14.png",
  },
  {
    src: "/images/v1/tools/tools/15.png",
  },
  {
    src: "/images/v1/tools/tools/16.png",
  },
]

export default function ToolsAndTech() {
  const [number, setNumber] = useState(1);

	useEffect(() => {
    const interval = setInterval(() => setNumber(value => value < 2600 ? value + 1 : 1), 0);
    return () => clearInterval(interval);
	}, []);

	return (
		<section
			className="toolsAndTech"
			style={{ paddingTop: 0, overflow: 'hidden' }}
			data-aos="fade-center"
		>
      <h3 className="section-title black">Tools & Technologies we use</h3>
			<div
				id="toolsAndTech"
				style={{
          transform: `translate3d(-${number}px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
          willChange: "transform",
          transformStyle: "preserve-3d",
				}}
			>
        {tools.map((item, idx) => (
          <img
            key={idx}
            src={item.src}
            loading="lazy"
            width={200}
            style={{
              marginRight: "70px",
              filter: "grayscale(100%)"
            }}
            alt=""
          />
        ))}
			</div>
		</section>
	);
}