
import AOS from 'aos';
import "aos/dist/aos.css";
import { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AdminRoute from './components/AdminRoute';
import PublicRoute from './components/PublicRoute';
import AdminAuth from './pages/AdminAuth';
import AdminDashboard from './pages/AdminDashboard';
import HomePage from './pages/Home';
import AuthProvider from './provider/AuthProvider';

function App() {
  useEffect(() => {
    AOS.init({
      duration : 500
    });
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <PublicRoute exact path="/admin" component={AdminAuth} />
          <AdminRoute exact path="/admin/dashboard" component={AdminDashboard} />
        </Switch>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
