import { useState } from "react";

export default function Dropdown({ children, options = [] }) {
  const [open, setOpen] = useState(false);
  
  return (
    <div className="dropdown">
      <div className="dropdown__trigger" onClick={() => setOpen(state => !state)}>{children}</div>
      <div className={`dropdown__options ${open ? "active" : ""}`}>
        {options.map((opt, ind) => <span onClick={() => {
          setOpen(false);
          opt?.onClick(opt);
        }} key={ind}>{opt.label}</span>)}
      </div>
    </div>
  )
}