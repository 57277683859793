export default function Banner({ children, id }) {
  return (
    <section className="banner" id={id}>
      <div className="container">
        <p data-aos="fade-center">
          {children}
        </p> 
      </div>
    </section>  
  )
}