import { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();
const INITIAL_AUTH_STATE = {
  processing: true,
  isAuthenticated: false,
  user: null
}

export const useAuth = () => useContext(AuthContext);
export default function AuthProvider ({ children }) {
  const [authState, setAuthState] = useState(INITIAL_AUTH_STATE);

  useEffect(() => {
    getAuthUser();
  }, []);

  const login = (data) => {
    return fetch("/api/auth", { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(data) })
      .then((res) => res.json())
      .then((data) => {
        if (data?.error) return Promise.reject(data.error);
        localStorage.setItem("tid", data);
        getAuthUser(data);
        return Promise.resolve(data);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  const getAuthUser = (token) => {
    fetch("/api/auth", { headers: { Authorization: "Bearer " + (token || localStorage.getItem("tid")) } })
      .then((res) => res.json())
      .then((data) => setAuthState(state => ({ ...state, isAuthenticated: !!data, user: data })))
      .finally(() => setAuthState(state => ({ ...state, processing: false })));
  }

  const logout = (data) => {
    return new Promise((resolve) => {
      localStorage.removeItem("tid");
      setAuthState({...INITIAL_AUTH_STATE, processing: false});
      resolve(true);
    })
  }

  return (
    <AuthContext.Provider value={{ authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
} 