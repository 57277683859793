import { Link } from "react-scroll";

export default function WhatWeDo() {
  return (
    <section className="whatWeDo" id="whatWeDo" style={{
      background: 'url("/images/v1/section_bg.png")'
    }}>
      <div className="container">
        <h3 className="section-title">What We Do</h3>

        <div className="whatWeDo__card" data-aos="fade-right">
          <div className="whatWeDo__img">
            <img src="/images/v1/organic-traffic.png" alt="" />
          </div>

          <div>
            <h4>Increasing Brand Aware traffic (online/offline)</h4>
            <p>We help you create value for your consumers and increase traffic by helping you express your brand’s unique personality and offerings to help you stand out. Our targeted and data driven approach helps ensure your brand is seen and heard in the right light. </p>
          </div>
        </div>

        <div className="whatWeDo__card" data-aos="fade-left">
          <div className="whatWeDo__img">
            <img src="/images/v1/software-solution.png" alt="" />
          </div>

          <div>
            <h4>Custom Software Solutions</h4>
            <p>We’ll help create custom digital solutions to create efficiency across the value chain to deliver a smooth experience for your customers. We develop software that can scale with your company — from fully-customized management systems and software to websites and mobile apps.</p>
          </div>
        </div>

        <div className="whatWeDo__card" data-aos="fade-right">
          <div className="whatWeDo__img">
            <img src="/images/v1/branded-merch.png" alt="" />
          </div>

          <div>
            <h4>Branded Merchandise Manufacturing</h4>
            <p>We can help create high-quality branded merchandise that your customers will wear proudly and comfortably. Our access to the entire process from sourcing raw materials to working directly with apparel manufacturers ensures that we deliver the best products at competitive rates.</p>
          </div>
        </div>

        <div style={{ textAlign: 'center', marginTop: "66px" }}>
          <Link to="contact" spy={true} smooth={true} offset={50} duration={500}>
            <button className="btn btn-primary text-bold" data-aos="fade-right">GET IN TOUCH</button>
          </Link>
        </div>
      </div>
    </section>
  )
}