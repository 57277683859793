import { Link } from "react-scroll";

export default function WorkWithUs() {
  return (
    <section className="workWithUs" id="whoWeWorkWith">
      <div className="container">
        <h3>Who We Work With
        <Link to="contact" spy={true} smooth={true} offset={50} duration={500}>
          <button className="btn btn-primary">INQUIRE ABOUT YOUR INDUSTRY</button>
        </Link>
        </h3>
      </div>
        <div className="workWithUs__cards">
          <div className="workWithUs__card"  data-aos="fade-center">
            <img src="/images/Finance.png" alt="" />
            <h2>Financial Service Providers</h2>
          </div>
          <div className="workWithUs__card"  data-aos="fade-center">
            <img src="/images/RetailBusiness.png" alt="" />
            <h2>Retail Businesses</h2>
          </div>
          <div className="workWithUs__card"  data-aos="fade-center">
            <img src="/images/v1/ecommerce_business.png" alt="" />
            <h2>ECommerce Businesses</h2>
          </div>
          <div className="workWithUs__card"  data-aos="fade-center">
            <img src="/images/v1/Entertainment_Industry.png" alt="" />
            <h2>Entertainment Industry</h2>
          </div>
          <div className="workWithUs__card"  data-aos="fade-center">
            <img src="/images/more.png" alt="" />
            <h2>And More</h2>
          </div>
        </div>
    </section>
  )
}