import Dropdown from "./Dropdown";
import { useEffect, useState, useCallback, useRef } from "react";
import moment from "moment";
import Swl from "sweetalert2";
import { BiDownload } from "react-icons/bi";
import axios from "axios";
import { CSVLink } from "react-csv";

export default function ContactList() {
	const csvLink = useRef(null);
	const [sort, setSort] = useState(null);
	const [contactResponses, setContactResponses] = useState([]);

	useEffect(() => {
		getContactResponses();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sort]);

	const getContactResponses = useCallback(() => {
		axios({
			url: "/api/contacts",
			params: {
				...(sort ? { sort: JSON.stringify(sort) } : {}),
			},
			headers: {
				Authorization: "Bearer " + localStorage.getItem("tid"),
			},
		}).then(({ data }) => {
			setContactResponses(data);
		});
	}, [sort]);

	const deleteContactReply = (id) => {
		fetch(`/api/contacts/${id}`, {
			method: "DELETE",
			headers: { Authorization: "Bearer " + localStorage.getItem("tid") },
		})
			.then((res) => res.json())
			.then((data) => {
				getContactResponses();
				Swl.fire({
					icon: "success",
					title: data,
					showCloseButton: false,
					timer: 1000,
				});
			});
	};

	const csvData = [
		["firstname", "lastname", "email"],
		["John", "Doe", "john.doe@xyz.com"],
		["Jane", "Doe", "jane.doe@xyz.com"],
	];

	return (
		<div className="contactList">
			<h3>
				Contact List
				<span>
					<select
						onChange={({ target }) =>
							setSort({
								[target.value.split(":")[0]]: +target.value.split(":")[1],
							})
						}
					>
						<option value="">Sort</option>
						<option value="createdAt:1">Date (ASC)</option>
						<option value="createdAt:-1">Date (DESC)</option>
					</select>
					<button onClick={() => csvLink.current?.link.click()}>
						<CSVLink
							data={[
                ["Date", "Name", "Email", "Phone", "Collab Interests", "Found Us"],
                ...contactResponses.map(item => [
                  moment(item.createdAt).format("YYYY-MM-DD"),
                  item.name,
                  item.email,
                  item.phone,
                  item.ways_of_collaborate.join(", "),
                  item.hearingWays.join(", "),
                ])
              ]}
							filename="contacts.csv"
							className="hidden"
							ref={(r) => (csvLink.current = r)}
							target="_blank"
						/>
						<BiDownload /> Download
					</button>
				</span>
			</h3>

			<div className="table">
				<table>
					<thead>
						<tr>
							<th>Date</th>
							<th>Name</th>
							<th>Email</th>
							<th>Phone</th>
							<th>Collab Interests</th>
							<th>Found Us</th>
						</tr>
					</thead>
					<tbody>
						{contactResponses.map((item) => (
							<tr key={item._id}>
								<td>{moment(item.createdAt).format("YYYY-MM-DD")}</td>
								<td>{item.name}</td>
								<td>{item.email}</td>
								<td>{item.phone}</td>
								<td>
									{item.ways_of_collaborate.map((item) => (
										<div key={item}>{item}</div>
									))}
								</td>
								<td>
									<span>
										<div>
											{item.hearingWays.map((item) => (
												<div key={item}>{item}</div>
											))}
										</div>
										<Dropdown
											options={[
												{
													label: "Delete Response",
													value: item._id,
													onClick: (item) => deleteContactReply(item.value),
												},
											]}
										>
											<button type="button" style={{ marginLeft: "2rem" }}>
												...
											</button>
										</Dropdown>
									</span>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}