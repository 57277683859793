import { useState } from "react";

export default function AdminTab({ tabs }) {
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const { component: ContentComponent } = currentTab;

  return (
    <div className="adminTabs">
      <div className="adminTabs__buttons">
        {tabs.map((tab, ind) => <button className={currentTab.id === tab.id ? "active" : ""} key={ind} onClick={() => setCurrentTab(tab)} type="button">{tab.label}</button>)}
      </div>

      <div className="adminTabs__content">
        <ContentComponent />
      </div>
    </div>
  )
}